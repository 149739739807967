import { animate, motion, useAnimation } from 'framer-motion';
import React, { useEffect, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import styled, { keyframes, css } from 'styled-components';
import { Player, Controls } from '@lottiefiles/react-lottie-player';
import LottieLogo from './LottieLogo';
// const colorTransitionTime = 0.5; // seconds

interface SplashProps {
  showContent: (bool: boolean) => void;
}

export const RapptrSplash: React.FC<SplashProps> = ({ showContent }) => {
  const controls = useAnimation();

  const boxVariantsDisappear = {
    hidden: { opacity: 1 },
    visible: {
      opacity: [1, 0],
      transition: {
        duration: 0.5,
        delay: 0,
        type: 'easeIn',
      },
    },
  };

  return (
    <LogoDiv>
      <motion.div initial="hidden" animate={controls} variants={boxVariantsDisappear}>
        <LottieLogo
          restOfAnimation={() => {
            controls.start('visible');
            setTimeout(() => {
              showContent(true);
            }, 600);
          }}
        />
      </motion.div>
    </LogoDiv>
  );
};

const LogoDiv = styled.div`
  width: fit-content;
  display: flex;
  margin: 0px auto;
`;
