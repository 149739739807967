import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { useInView } from 'react-intersection-observer';
import { prismicClient, Prismic, RichText } from '../../services/prismic';
import imgImprintRatingStars from '../../images/ImprintRatingStars.svg';
import imgRealtorRatingStars from '../../images/RealtorRatingStars.svg';
import CountUp from 'react-countup';
import { motion } from 'framer-motion';
import { laptopSize } from '../../utils';
import { NewAppTitle, NewAppText, NewAppButton } from '../ui';
import { theme } from '../../utils/theme';
import { AiOutlineArrowRight } from 'react-icons/ai';
import _ from 'lodash';
import { Link } from 'gatsby';

interface ClientShelfProps {
  item: any;
  i: number;
  windowSize: number;
  currY: number;
  isMobile: boolean;
}

interface PointProp {
  point: any;
  color: string;
  endFunction: () => void;
}

const CreateCountUpComponent: React.FC<PointProp> = ({ point, color, endFunction }) => {
  // const countUpRef = React.useRef(null);

  // const { start } = useCountUp({
  //   ref: countUpRef,
  //   start: point.count_down ? 10000 : 0,
  //   end: Number(RichText.asText(point.number)),
  //   duration: 1,
  //   formattingFn: (num) => Number(num).toLocaleString(),
  //   decimals: RichText.asText(point.text).trim() === 'stars' ? 1 : 0,
  //   startOnMount: true,
  //   onEnd: () => endFunction(),
  // });

  // const throttledStart = _.throttle(function () {
  //   start();
  // }, 4000);

  // useEffect(() => {
  //   throttledStart();
  // }, []);

  // const { countUp, start, pauseResume, reset, update } = useCountUp({
  //   start: 0,
  //   end: 1234567,
  //   delay: 2,
  //   duration: 5,
  //   onReset: () => console.log('Resetted!'),
  //   onUpdate: () => console.log('Updated!'),
  //   onPauseResume: () => console.log('Paused or resumed!'),
  //   onStart: () => console.log('Started! 💨'),
  // });

  // useEffect(() => start(), []);

  return (
    // <ColoredSpan ref={countUpRef} color={color} />
    <CountUp
      redraw={false}
      end={Number(RichText.asText(point.number))}
      start={point.count_down ? 10000 : 0}
      duration={1}
      formattingFn={(num) => num.toLocaleString()}
      decimals={RichText.asText(point.text).trim() === 'stars' ? 1 : 0}
    >
      {({ countUpRef, start }) => (
        <div>
          <ColoredSpan ref={countUpRef} />
          <button onClick={start}>Start</button>
        </div>
      )}
    </CountUp>
    // <div ref={ref}>
    // {inView ?
    // : <span>{Number(RichText.asText(point.number))}</span>}
    // </div>
  );
};

const ClientShelf: React.FC<ClientShelfProps> = ({ item, i, windowSize, currY, isMobile }) => {
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 1,
  });

  const [pauseTransitions, setPauseTransitions] = useState(false);
  const [animationDone, setAnimationDone] = useState(false);

  // useEffect(() => {
  //   if (inView && animationDone !== 'run') {
  //     setAnimationDone('run');
  //   }
  // }, [inView]);

  const formatNumber = (value) => {
    return value.toLocaleString();
  };

  return (
    <ClientSegment key={item.id} backgroundColor={RichText.asText(item.data.bg_gradient)}>
      <ClientInfoDiv
        swapSides={item.data.image_at_left}
        window={windowSize}
        idx={i}
        currY={currY}
        isMobile={isMobile}
      >
        <ClientDetailsDiv
          // visible={i === promotedClients.length - 1}
          visible={false}
        >
          <img src={item.data.logo.url} alt={RichText.asText(item.data.client_name)} />
          <ClientText
            color={!!item.data && !!item.data.main_text_color ? item.data.main_text_color : ''}
          >
            {RichText.asText(item.data.text)}
          </ClientText>
          <BulletsContainer>
            {/* <CreateAnimatingBorder
            key={item.uid}
            index={i}
            color={
              !!item.data && !!item.data.bullets_border_color
              ? item.data.bullets_border_color
                : ''
            }
          /> */}
            <LeftBorderDiv
              ref={ref}
              color={
                !!item.data && !!item.data.bullets_border_color
                  ? item.data.bullets_border_color
                  : ''
              }
              inView={inView}
            />
            {item.data.bullet_points.map((point: any, index: number) => (
              <BulletPointsDiv key={`${point.number}${point.text}${index}`}>
                <BulletNumber
                  color={
                    !!item.data && !!item.data.bullet_number_colors
                      ? item.data.bullet_number_color
                      : ''
                  }
                >
                  <ColoredSpan color={item.data.bullets_border_color}>{point.prefix}</ColoredSpan>
                  {/* <CountUp
                    redraw={false}
                    end={Number(RichText.asText(point.number))}
                    start={point.count_down ? 10000 : 0}
                    duration={1}
                    formattingFn={(num) => num.toLocaleString()}
                    decimals={RichText.asText(point.text).trim() === 'stars' ? 1 : 0}
                  >
                    {({ countUpRef, start }) => {
                      const throttledStart = _.throttle(function () {
                        start();
                      }, 4000);

                      useEffect(() => {
                        throttledStart();
                      }, []);

                      return <ColoredSpan ref={countUpRef} />;
                    }}
                  </CountUp> */}
                  {/* {RichText.asText(point.number)} */}

                  {/* {animationDone === 'run' && inView ? ( */}
                  {/* <CreateCountUpComponent
                    point={point}
                    color={item.data.bullets_border_color}
                    endFunction={() => setAnimationDone('done')}
                  /> */}
                  {/* ) : (
                    <ColoredSpan color={item.data.bullets_border_color}>
                      {Number(RichText.asText(point.number)).toLocaleString()}
                    </ColoredSpan>
                  )} */}
                  {/* {animationDone !== 'run' && (
                    <ColoredSpan color={item.data.bullets_border_color}>
                      {Number(RichText.asText(point.number)).toLocaleString()}
                    </ColoredSpan>
                  )} */}
                  {/* <CountUp
                    redraw={false}
                    end={Number(RichText.asText(point.number))}
                    start={point.count_down ? 10000 : 0}
                    duration={1}
                    formattingFn={(num) => num.toLocaleString()}
                    decimals={RichText.asText(point.text).trim() === 'stars' ? 1 : 0}
                  /> */}
                  {animationDone ? (
                    <ColoredSpan color={item.data.bullets_border_color}>
                      {Number(RichText.asText(point.number)).toLocaleString()}
                    </ColoredSpan>
                  ) : (
                    <CountUp
                      redraw={false}
                      end={Number(RichText.asText(point.number))}
                      start={point.count_down ? 10000 : 0}
                      duration={1}
                      // formattingFn={(num) => num.toLocaleString()}
                      decimals={RichText.asText(point.text).trim() === 'stars' ? 1 : 0}
                      onEnd={() => setAnimationDone(true)}
                      onStart={() => setPauseTransitions(true)}
                    >
                      {({ countUpRef, start }) => {
                        const throttledStart = _.throttle(function () {
                          start();
                        }, 999999);

                        useEffect(() => {
                          if (inView && !pauseTransitions) {
                            throttledStart();
                          }
                        }, [inView]);
                        return (
                          <ColoredSpan ref={countUpRef} color={item.data.bullets_border_color} />
                        );
                      }}
                    </CountUp>
                  )}
                  <ColoredSpan color={item.data.bullets_border_color}>{point.suffix}</ColoredSpan>
                </BulletNumber>
                <BulletText
                  color={
                    !!item.data && !!item.data.bullet_text_color ? item.data.bullet_text_color : ''
                  }
                >
                  {RichText.asText(point.text).trim() === 'stars' ? (
                    item.uid === 'imprint' ? (
                      <img src={imgImprintRatingStars} alt="App rating stars" />
                    ) : (
                      item.uid === 'realtor' && <img src={imgRealtorRatingStars} alt="App rating stars" />
                    )
                  ) : (
                    RichText.asText(point.text)
                  )}
                </BulletText>
              </BulletPointsDiv>
            ))}
            {!!item.data && !!item.data.bullets_footer && (
              <FooterText>{RichText.asText(item.data.bullets_footer)}</FooterText>
            )}
          </BulletsContainer>
          <p></p>
          <DetailsButtonDesktop
            to={!!item.data && item.data.details_link.url ? item.data.details_link.url : '#'}
            color={
              !!item.data && !!item.data.details_button_color ? item.data.details_button_color : ''
            }
          >
            Project Details &nbsp;
            <AiOutlineArrowRight
              color={
                !!item.data && !!item.data.details_button_color
                  ? item.data.details_button_color
                  : ''
              }
            />
          </DetailsButtonDesktop>
        </ClientDetailsDiv>
        <ClientDetailsDiv>
          <ClientSplash
            swapSides={item.data.image_at_left}
            // animate={{
            //   y: [
            //     ...Array(11).keys(),
            //     ...Array.from({ length: 9 }, (_, i) => 9 - i),
            //     ...Array.from({ length: 11 }, (_, i) => -1 * i),
            //     ...Array.from({ length: 10 }, (_, i) => i - 9),
            //   ],
            // }}
            // transition={{ repeat: Infinity, duration: 5, type: 'spring' }}
            src={item.data.splash_art.url}
            alt={RichText.asText(item.data.client_name)}
          />
        </ClientDetailsDiv>
        <MobileDiv>
          {item.data.bullet_points.map((point: any, index: number) => (
            <BulletPointsDiv key={`${point.number}${point.text}${index}`}>
              <BulletNumber
                color={
                  !!item.data && !!item.data.bullet_number_color
                    ? item.data.bullet_number_color
                    : ''
                }
              >
                {point.prefix}
                {Number(RichText.asText(point.number)).toLocaleString()}
                {point.suffix}
              </BulletNumber>
              <BulletText
                color={
                  !!item.data && !!item.data.bullet_text_color ? item.data.bullet_text_color : ''
                }
              >
                {RichText.asText(point.text).trim() === 'stars' ? (
                  item.uid === 'imprint' ? (
                    <img src={imgImprintRatingStars} alt="App rating stars" />
                  ) : (
                    item.uid === 'realtor' && <img src={imgRealtorRatingStars} alt="App rating stars"/>
                  )
                ) : (
                  RichText.asText(point.text)
                )}
              </BulletText>
            </BulletPointsDiv>
          ))}
          <DetailsButtonMobile
            to={!!item.data && item.data.details_link.url ? item.data.details_link.url : '#'}
            color={
              !!item.data && !!item.data.details_button_color ? item.data.details_button_color : ''
            }
          >
            Project Details{' '}
            <AiOutlineArrowRight
              color={
                !!item.data && !!item.data.details_button_color
                  ? item.data.details_button_color
                  : ''
              }
            />
          </DetailsButtonMobile>
        </MobileDiv>
      </ClientInfoDiv>
    </ClientSegment>
  );
};

export default function WorkShelves() {
  const [y, setY] = useState(0);
  const [windowSize, setWindowSize] = useState(window.innerHeight);
  const [promotedClients, setPromotedClients] = useState([] as any[]);
  const [isLoading, setIsLoading] = useState(true);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 992);

  useEffect(() => {
    const updateScrollY = () => setY(window.scrollY);
    const handleResize = () => {
      setWindowSize(window.innerHeight);
      setIsMobile(window.innerWidth <= 992);
    };
    window.addEventListener('scroll', updateScrollY);
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('scroll', updateScrollY);
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      const response = await prismicClient.query(
        Prismic.Predicates.at('document.type', 'promoted-clients'),
        { pageSize: 9999 },
      );
      console.log('prismic response: ', response);
      if (response && response.results.length) {
        console.log('DATA: ', response.results);
        setPromotedClients(response.results as any[]);
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  return (
    <Container id="work-shelves">
      {isLoading ? (
        <div />
      ) : (
        promotedClients
          .slice()
          .sort((a: any, b: any) => a.data.sort_order - b.data.sort_order)
          .map((item: any, i: number) => (
            <ClientShelf item={item} i={i} windowSize={windowSize} currY={y} isMobile={isMobile} />
          ))
      )}
    </Container>
  );
}

const Container = styled.div``;

const MobileDiv = styled.div`
  display: none;

  @media ${laptopSize} {
    width: 100%;
    padding: 0px 60px;
    display: flex;
    flex-direction: column;
  }
`;

const FooterText = styled(NewAppText as any)`
  font-style: italic;
  font-weight: normal;
  font-size: 14px;
  line-height: 1;
  color: rgba(128, 130, 133, 0.79);
`;

interface BorderProps {
  color?: string;
  animationCompleted?: boolean;
  inView: boolean;
}

const LeftBorderDiv = styled(motion.div)<BorderProps>`
  position: absolute;
  width: 1px;
  left: 0px;
  top: 0px;
  /* height: ${(props) => (props.animationCompleted ? '100%' : '0px')}; */
  height: ${(props) => (props.inView ? 100 : 0)}%;
  background-color: ${(props) => (!!props.color ? props.color : theme.BLACK_COLOR)};
  transition: height 1s ease-in-out;
`;

interface ColorProp {
  color?: string;
}

const ColoredSpan = styled.span<ColorProp>`
  color: ${(props) => props.color ?? theme.BLACK_COLOR};
`;

const DetailsButtonDesktop = styled(Link) <ColorProp>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
  border: 1px solid black;
  border-color: ${(props) => (!!props.color ? props.color : theme.BLACK_COLOR)};
  border-radius: 7px;
  padding: 5px 30px;
  margin-top: 22px;
  color: ${(props) => (!!props.color ? props.color : theme.BLACK_COLOR)};
  font-family: ${theme.PRIMARY_FONT};
  font-weight: 500;
  transition: all 0.15s ease-in-out;
  @media ${laptopSize} {
    display: none;
  }
  * {
    transition: all 0.15s ease-in-out;
  }
  :hover {
    border: 1px solid ${theme.WHITE_COLOR};
    text-decoration: none;
    background-color: ${theme.MAIN_DARK_BLUE};
    color: ${theme.WHITE_COLOR};
    * {
      color: ${theme.WHITE_COLOR};
    }
  }

`;

const DetailsButtonMobile = styled(Link) <ColorProp>`
  display: flex;
  align-items: center;
  justify-content: center;
  /* width: fit-content; */
  border: 1px solid black;
  border-color: ${(props) => (!!props.color ? props.color : theme.BLACK_COLOR)};
  border-radius: 7px;
  padding: 5px 30px;
  margin-top: 22px;
  color: ${(props) => (!!props.color ? props.color : theme.BLACK_COLOR)};
  font-family: ${theme.PRIMARY_FONT};
  font-weight: 500;
`;

const BulletsContainer = styled.div<ColorProp>`
  padding: 24px;
  padding-bottom: 0px;
  position: relative;
  z-index: 2;
  @media ${laptopSize} {
    display: none;
  }
`;

interface ClientSplashProps {
  swapSides?: boolean;
}

const ClientSplash = styled(motion.img)<ClientSplashProps>`
  max-width: 45vw;
  max-height: 70vh;
  ${(props) =>
    props.swapSides &&
    css`
      margin-right: 150px;
    `}
  @media ${laptopSize} {
    max-width: 60vw;
    max-height: 90vh;
    margin-top: 30px;
    margin-bottom: 30px;
    ${(props) =>
      props.swapSides &&
      css`
        margin-right: 0px;
      `}
  }
`;

const BulletNumber = styled(NewAppText as any)<ColorProp>`
  font-weight: bold;
  font-size: 32px;
  line-height: 1;
  margin-bottom: 5px;
  color: ${(props) => (!!props.color ? props.color : theme.BLACK_COLOR)};
  z-index: 2;
  @media ${laptopSize} {
    text-align: center;
  }
`;

const BulletText = styled(NewAppText as any)<ColorProp>`
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 1;
  margin-bottom: 27px;
  color: ${(props) => (!!props.color ? props.color : theme.BLACK_COLOR)};
  z-index: 2;
  @media ${laptopSize} {
    text-align: center;
  }
`;

const BulletPointsDiv = styled.div`
  display: flex;
  flex-direction: column;
`;

interface VisibleProp {
  visible?: boolean;
}

const ClientDetailsDiv = styled.div<VisibleProp>`
  /* margin: auto 0px;
  max-width: 420px; */
  place-self: center;
  /* display: none; */
  /* display: ${(props) => (props.visible ? 'block' : 'none')}; */
  @media ${laptopSize} {
    /* padding: 40px; */
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;

const ClientText = styled(NewAppTitle as any)<ColorProp>`
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 48px;
  letter-spacing: 0px;
  color: ${(props) => (!!props.color ? props.color : theme.BLACK_COLOR)};
  max-width: 420px;
  margin-top: 24px;
  @media ${laptopSize} {
    margin-top: 36px;
    font-size: 35px;
    line-height: 45px;
    text-align: center;
    padding: 0 24px;
  }
`;

interface ClientInfoProps {
  swapSides?: boolean;
  window: number;
  idx: number;
  currY: number;
  isMobile: boolean;
}

const ClientInfoDiv = styled.div.attrs((props: ClientInfoProps) => ({
  style: {
    height: props.isMobile
      ? '100%'
      : props.currY - (props.idx + 1) * props.window >= props.window // If the scroll has past the div entirely,
      ? 0 //    then height is 0
      : props.currY > (props.idx + 1) * props.window // If the scroll has started to go past the div...
      ? props.window - (props.currY - (props.idx + 1) * props.window) //    then the height is the remaining space
      : props.window, //    otherwise the height is the full window height
  },
}))<ClientInfoProps>`
  position: absolute;
  left: 0;
  bottom: 0;
  /* min-height: 100vh; */
  width: 100vw;
  display: flex;
  flex-direction: ${(props) => (props.swapSides ? 'row-reverse' : 'row')};
  /* margin: auto auto; */
  /* justify-items: stretch;
  margin-top: auto; */
  justify-content: center;
  padding-top: 0px;
  padding-bottom: 40px;

  @media ${laptopSize} {
    position: relative;
    flex-direction: column;
    height: 100%;
    padding-top: 48px;
    padding-bottom: 48px;
  }
`;

// SECOND VERSION THAT USES TOP INSTEAD OF HEIGHT
// const ClientInfoDiv = styled.div.attrs((props: ClientInfoProps) => ({
//   style: {
//     top: props.currY - ((props.idx + 1) * props.window) >= props.window ?  // If the scroll has past the div entirely,
//       props.window :                                                                     //    then height is 0
//       props.currY > (props.idx + 1) * props.window ?                          // If the scroll has started to go past the div...
//         props.currY - (props.idx + 1) * props.window :       //    then the height is the remaining space
//         0,                                                         //    otherwise the height is the full window height
//   }
// })) <ClientInfoProps>`
//   position: absolute;
//   left: 0;
//   bottom: 0;
//   /* min-height: 100vh; */
//   width: 100vw;
//   height: 100vh;
//   display: flex;
//   /* align-items: center; */
//   flex-direction: ${(props) => (props.swapSides ? 'row-reverse' : 'row')};
//   /* margin: auto auto; */
//   /* justify-items: stretch;
//   margin-top: auto; */
//   justify-content: center;
//   /* align-items: center; */
//   padding-top: 40px;
//   padding-bottom: 40px;

//   @media ${laptopSize} {
//     flex-direction: column;
//   }
// `;

interface ClientProps {
  backgroundColor: string;
}

const ClientSegment = styled.div<ClientProps>`
  position: relative;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  background: ${(props) => props.backgroundColor};

  @media ${laptopSize} {
    height: auto;
  }
`;
