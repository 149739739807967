import * as React from 'react';
import styled from 'styled-components';
import { NewAppTitle, NewAppText, NewAppButton } from '../ui';
import { theme } from '../../utils/theme';
import hexagonBG from '../../images/hexagons2.svg';
import { Formik, FormikProps, Field } from 'formik';
// import { trackCustomEvent } from 'gatsby-plugin-google-analytics';
import * as Yup from 'yup';
import { api } from '../../backend/hubspotApi';
import Select from 'react-select';
import { laptopSize, phoneRegExp } from '../../utils/variables';


const companyOptions = [
  { label: 'Idea Phase Startup', value: 'Idea Phase Startup' },
  { label: 'Development Phase Startup', value: 'Development Phase Startup' },
  { label: 'Existing Business', value: 'Existing Business' },
];

const formSchema = Yup.object().shape({
  firstname: Yup.string().required('First name is required.'),
  lastname: Yup.string().required('Last name is required.'),
  phone: Yup.string()
    .required('Phone number is required')
    .matches(phoneRegExp, 'Phone number is not valid'),
  email: Yup.string().required('Email is required.').email('Email format is not valid'),
  company: Yup.string().required('Company is required.'),
  // organization_type: Yup.string().required('* Company type is required.'),
});

interface MyFormikProps {
  firstname: string;
  lastname: string;
  phone: string;
  email: string;
  company: string;
  organization_type: string;
  message: string;
}

const LetsTalk = () => {
  const customStyles = {
    menu: (provided, state) => ({
      ...provided,
      color: '#3b474d',
    }),
    singleValue: (provided, state) => ({
      ...provided,
      color: '#3b474d',
    }),
  };
  return (
    <BlueBackgroundDiv id="contact-us">
      <MainText>Let’s Talk.</MainText>
      <MainBody>
        From high-growth startups to enterprise brands, we help companies of all sizes launch
        digital products that drive user engagement. We’d love to discuss how we can help you.
      </MainBody>
      <FormContainer>
        <Formik
          initialValues={{
            firstname: '',
            lastname: '',
            phone: '',
            email: '',
            company: '',
            organization_type: '',
            message: '',
          }}
          validationSchema={formSchema}
          onSubmit={async (values) => {
            // alert(JSON.stringify(values, null, 2));
            await api.sendForm(values);
            // trackCustomEvent({
            //   action: 'Successful contact us form submission',
            //   category: 'Homepage form',
            //   label: 'Sucessful contact us form submission',
            // });
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            setFieldValue,
          }: FormikProps<MyFormikProps>) => (
            <form onSubmit={handleSubmit}>
              <FormDiv>
                <FormField>
                  <InputDiv>
                    <Input
                      id="firstname"
                      type="firstname"
                      name="firstname"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.firstname}
                      placeholder="First Name"
                      // onClick={() =>
                      //   trackCustomEvent({
                      //     action: 'First name form field click',
                      //     category: 'Homepage form',
                      //     label: 'First name form field click',
                      //   })
                      // }
                    />
                    {errors.firstname && touched.firstname ? (
                      <ErrorText>{errors.firstname}</ErrorText>
                    ) : (
                      <ErrorSpace />
                    )}
                  </InputDiv>
                  <InputDiv>
                    <Input
                      id="lastname"
                      type="lastname"
                      name="lastname"
                      placeholder="Last Name"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.lastname}
                      // onClick={() =>
                      //   trackCustomEvent({
                      //     action: 'Last name form field click',
                      //     category: 'Homepage form',
                      //     label: 'Last name form field click',
                      //   })
                      // }
                    />
                    {errors.lastname && touched.lastname ? (
                      <ErrorText>{errors.lastname}</ErrorText>
                    ) : (
                      <ErrorSpace />
                    )}
                  </InputDiv>
                </FormField>
                <FormField>
                  <InputDiv>
                    <Input
                      id="phone"
                      type="phone"
                      name="phone"
                      placeholder="Phone"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.phone}
                      // onClick={() =>
                      //   trackCustomEvent({
                      //     action: 'phone form field click',
                      //     category: 'Homepage form',
                      //     label: 'phone form field click',
                      //   })
                      // }
                    />
                    {errors.phone && touched.phone ? (
                      <ErrorText>{errors.phone}</ErrorText>
                    ) : (
                      <ErrorSpace />
                    )}
                  </InputDiv>
                  <InputDiv>
                    <Input
                      id="email"
                      type="email"
                      name="email"
                      placeholder="Email"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.email}
                      // onClick={() =>
                      //   trackCustomEvent({
                      //     action: 'email form field click',
                      //     category: 'Homepage form',
                      //     label: 'email form field click',
                      //   })
                      // }
                    />
                    {errors.email && touched.email ? (
                      <ErrorText>{errors.email}</ErrorText>
                    ) : (
                      <ErrorSpace />
                    )}
                  </InputDiv>
                </FormField>
                <FormField>
                  <InputDiv>
                    <Input
                      id="company"
                      type="company"
                      name="company"
                      placeholder="Company"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.company}
                      // onClick={() =>
                      //   trackCustomEvent({
                      //     action: 'company name form field click',
                      //     category: 'Homepage form',
                      //     label: 'company name form field click',
                      //   })
                      // }
                    />
                    {errors.company && touched.company ? (
                      <ErrorText>{errors.company}</ErrorText>
                    ) : (
                      <ErrorSpace />
                    )}
                  </InputDiv>
                  <InputDiv>
                    <Field name={'organization_type'}>
                      {({ form, field }) => {
                        return (
                          <StyledSelect
                            options={companyOptions}
                            placeholder="Business Phase"
                            // id="organization_type"
                            name="organization_type"
                            styles={customStyles}
                            // type="organization_type"
                            // clearable={false}
                            // searchable={false}
                            onChange={(option) => setFieldValue(field.name, option.value)}
                            onBlur={handleBlur}
                            // onClick={() =>
                            //   trackCustomEvent({
                            //     action: 'organization type form field click',
                            //     category: 'Homepage form',
                            //     label: 'organization type form field click',
                            //   })
                            // }
                          />
                        );
                      }}
                    </Field>
                    {/* <StyledSelect
                    name="organization_type"
                    value={values.organization_type}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    // style={{ display: 'block' }}
                  >
                    <option value="" label="Select" />
                    <option value="Idea Phase Startup" label="Idea Phase Startup" />
                    <option value="Development Phase Startup" label="Development Phase Startup" />
                    <option value="Existing Business" label="Existing Business" />
                  </StyledSelect> */}
                    {errors.organization_type && touched.organization_type ? (
                      <ErrorText>{errors.organization_type}</ErrorText>
                    ) : (
                      <ErrorSpace />
                    )}
                  </InputDiv>
                </FormField>
                <TextAreaField>
                  <TextAreaDiv>
                    <TextArea
                      id="message"
                      type="message"
                      name="message"
                      placeholder="Tell Us About Your Project"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.message}
                      // onClick={() =>
                      //   trackCustomEvent({
                      //     action: 'message form field click',
                      //     category: 'Homepage form',
                      //     label: 'message form field click',
                      //   })
                      // }
                    />
                    {errors.message && touched.message ? (
                      <ErrorText>{errors.message}</ErrorText>
                    ) : (
                      <ErrorSpace />
                    )}
                    <SubmitButton
                      type="submit"
                      disabled={isSubmitting}
                      // onClick={() =>
                      //   trackCustomEvent({
                      //     action: 'Form submission attempt',
                      //     category: 'Homepage form',
                      //     label: 'Form submission attempt',
                      //   })
                      // }
                    >
                      Submit
                    </SubmitButton>
                  </TextAreaDiv>
                </TextAreaField>
              </FormDiv>
            </form>
          )}
        </Formik>
      </FormContainer>
    </BlueBackgroundDiv>
  );
};

const SubmitButton = styled(NewAppButton)`
  background-color: ${theme.MAIN_DARK_BLUE};
  border: none;
  border-radius: 7px;
  :hover {
    background-color: ${theme.WHITE_COLOR};
    color: ${theme.MAIN_DARK_BLUE};
  }
  @media ${laptopSize} {
    width: 60vw;
  }
`;

const StyledSelect = styled(Select)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  line-height: center;
  /* height: 22px; */
  height: 40px;
  background-color: white;
  width: 100%;
  /* width: 100%; */
  /* padding: 20px; */
  border-radius: 5.6px;
  border-width: 0;

  & > div {
    padding-left: 8px;
  }
`;

const TextArea = styled.textarea`
  display: flex;
  flex-direction: column;
  height: 92px;
  /* height: 47px; */
  width: 100%;
  /* max-width: 800px; */
  /* width: 100%; */
  padding: 20px;
  border-radius: 5.6px;
  border-width: 0;
  line-height: 1.1;
  max-height: 130px;
  min-height: 92px;
`;

const InputDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

const TextAreaField = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  /* width: 90vw; */
  height: 190px;
  width: 100%;
  margin: 0px;
  padding: 0px;
  /* max-width: 760px; */
  /* padding-bottom: 20px; */
`;

const TextAreaDiv = styled.div`
  display: flex;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0px;
  width: 100%;
`;

const FormContainer = styled.div`
  position: relative;
  width: 100%;
  max-width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 20px 0px 0px 0px;
`;

const FormField = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 40px;
  width: 100%;
  @media ${laptopSize} {
    grid-template-columns: 1fr;
    grid-gap: 0px;
  }
`;

const ErrorText = styled(NewAppText)`
  height: 28px;
  font-size: 14px;
  color: red;
  margin: 0px;
`;

const ErrorSpace = styled.div`
  height: 28px;
  width: 1px;
`;

const Input = styled.input`
  display: flex;
  flex-direction: column;
  height: 22px;
  /* height: 47px; */
  width: 100%;
  /* width: 100%; */
  padding: 20px;
  border-radius: 5.6px;
  border-width: 0;
`;

const FormDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  height: 100%;
  padding-bottom: 40px;
  width: 720px;
  @media ${laptopSize} {
    width: 80vw;
  }
`;

const MainBody = styled(NewAppText as any)`
  font-style: normal;
  font-size: 18px;
  line-height: 22px;
  max-width: 815px;
  text-align: center;
`;

const MainText = styled(NewAppTitle as any)`
  font-style: normal;
  font-weight: bold;
  font-size: 42px;
  line-height: 50px;
  text-align: center;
`;

const BlueBackgroundDiv = styled.div`
  /* background: url(${hexagonBG}); */
  background-color: ${theme.MID_BLUE};
  /* min-height: calc(100vw * 850 / 1920); */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 120px;
  padding-bottom: 40px;
  scroll-snap-align: start;
  @media ${laptopSize} {
    padding: 40px;
    padding-top: 80px;
  }
`;

export default LetsTalk;
