import React, { useEffect, useState } from 'react';
import styled, { keyframes } from 'styled-components';
import { laptopSize, mobileSize, smallMobileSize } from '../../utils';
import { theme } from '../../utils/theme';
import { RapptrSplash } from './RapptrSplash';
import arrowLeft from './../../images/HeroLeftChevron.png';
import { motion } from 'framer-motion';
import { FiSmartphone } from 'react-icons/fi';
import { AiOutlineTeam } from 'react-icons/ai';
import { NewAppButton, NewAppText } from '../ui';
import scrollTo from 'gatsby-plugin-smoothscroll';
import hexagonBG from '../../images/hexagons2.svg';

const HERO_ANIMATION_SECONDS = 2;

export default function HeroSplash() {
  // const controls = useAnimation();
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [animationsDone, setAnimationsDone] = useState(false);
  const texts = [
    'We Build Apps That Captivate',
    'That Are Crafted for Quality',
    'Delight Your Users',
    '& Grow Your Business',
  ];

  // decrement

  // const handleIndexChange = (number: number) => {
  //   if (number === 1) {
  //     setSelectedIndex((selectedIndex) => {
  //       return selectedIndex < texts.length - 1 ? selectedIndex + 1 : 0;
  //     });
  //   } else if (number === -1) {
  //     setSelectedIndex((selectedIndex) => {
  //       return selectedIndex > 0 ? selectedIndex - 1 : texts.length - 1;
  //     });
  //   }
  //   // controls.start('visible');
  // };
  // const handleIndexChangeMemo = useMemo(handleIndexChange, [selectedIndex])

  const incrementIndex = () =>
    setSelectedIndex((selectedIndex) => (selectedIndex + 1) % texts.length);

  let interval = null as any;

  useEffect(() => {
    if (animationsDone) {
      interval = setInterval(() => {
        incrementIndex();
      }, HERO_ANIMATION_SECONDS * 2 * 1000);
    }
    return () => clearInterval(interval);
  }, [animationsDone]);
  return (
    <PortfolioContainer baseColor={'black'} gradientColor={'#766767'}>
      <CenterDiv>
        <RelativeDiv>
          {!animationsDone && <RapptrSplash showContent={setAnimationsDone} />}
          {animationsDone && (
            <>
              <ArrowBackground>
                <NavArrow src={arrowLeft} alt="Left" />
              </ArrowBackground>
              <TextDiv key={`text-div-${selectedIndex}`}>
                <HiddenText key={`hidden-text-${selectedIndex}`}>{texts[selectedIndex]}</HiddenText>
              </TextDiv>
              <ArrowBackground right>
                <NavArrow src={arrowLeft} style={{ transform: 'rotate(180deg)' }} alt="Right" />
              </ArrowBackground>
              {texts.map((item, i) => (
                <SplashText key={item} showIdx={i} currIdx={selectedIndex}>
                  {item}
                </SplashText>
              ))}
            </>
          )}
        </RelativeDiv>
        <MinHeightDiv>
          {animationsDone && (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{
                type: 'easeIn',
              }}
            >
              <SecondaryText>
                Create a premium mobile or web app experience that people love to use.
              </SecondaryText>
              <ButtonsDiv>
                <HeroButton onClick={() => scrollTo('#work-shelves')}>
                  <FiSmartphone color={theme.LIGHT_BLUE} /> Our Work
                </HeroButton>
                <HeroButton onClick={() => scrollTo('#contact-us')}>
                  <AiOutlineTeam color={theme.LIGHT_BLUE} /> Let&apos;s Talk
                </HeroButton>
              </ButtonsDiv>
            </motion.div>
          )}
        </MinHeightDiv>
      </CenterDiv>
    </PortfolioContainer>
  );
}

const HideOnMobileDiv = styled.div`
  display: block;
  @media ${laptopSize} {
    display: none;
  }
`;

const RelativeDiv1 = styled.div`
  position: relative;
`;

interface StickProps {
  inView: boolean;
}

const MinHeightDiv2 = styled.div<StickProps>`
  min-height: 100vh;
  background-color: transparent;
  bottom: 0px;
  width: 100%;
`;

const MinHeightDiv3 = styled.div`
  min-height: 100vh;
  background-color: blue;
`;

// const NewIndexLayout = styled(IndexLayout)``;

const HeroButton = styled(NewAppButton)`
  font-size: 16px;
  :hover {
    background-color: ${theme.WHITE_COLOR};
    color: ${theme.MAIN_DARK_BLUE};
  }

  @media ${mobileSize} {
    width: 100%;
  }
`;

const SecondaryText = styled(NewAppText)`
  font-size: 21px;
  text-align: center;
  @media ${laptopSize} {
    padding: 0px 40px;
  }
`;

const MinHeightDiv = styled.div`
  height: 150px;
  display: flex;
  align-items: start;
  justify-content: center;
  width: 100%;
`;

const arrowWidthAnimation = keyframes`
  0% { width: 100%; }
  35% { width: 60px; }
`;

const arrowWidthAnimationMobile = keyframes`
  0% { width: 100%; }
  35% { width: 38px; }
`;

const ArrowBackground = styled.div<{ right?: boolean }>`
  display: flex;
  justify-content: ${(props) => (props.right ? 'flex-start' : 'flex-end')};
  align-items: center;
  height: 100%;
  background-color: ${theme.BACKGROUND_COLOR};
  /* background: ${(props) =>
    props.right
      ? `linear-gradient(to left, ${theme.BACKGROUND_COLOR} 90%, transparent 100%)`
      : `linear-gradient(to right, ${theme.BACKGROUND_COLOR} 90%, transparent 100%)`}; */
  animation: ${arrowWidthAnimation} ${HERO_ANIMATION_SECONDS}s ease-in-out infinite alternate;

  @media ${smallMobileSize} {
    animation: ${arrowWidthAnimationMobile} ${HERO_ANIMATION_SECONDS}s ease-in-out infinite
      alternate;
  }
`;

const NavArrow = styled.img`
  display: inline-block;
  height: 167px;
  min-width: 60px;

  @media ${laptopSize} {
    min-width: 44px;
    height: 132px;
  }
  @media ${smallMobileSize} {
    min-width: 38px;
    max-width: 38px;
    height: 106px;
  }
`;

const SplashText = styled.h1<{ showIdx: number; currIdx: number }>`
  position: absolute;
  left: 0;
  font-family: 'ObjektivMk2';
  font-weight: bold;
  font-size: 62px;
  line-height: 72px;
  margin: auto auto;
  color: ${theme.WHITE_COLOR};
  text-align: center;
  /* width: clamp(50%, 800px, 90%); */
  /* min-width: auto; */
  /* width: fit-content; */
  /* width: 840px; */
  padding-left: 40px;
  padding-right: 40px;
  white-space: nowrap;
  overflow: hidden;
  width: 100%;
  opacity: ${(props) => (props.showIdx === props.currIdx ? 1 : 0)};
  z-index: -1;
  @media screen and (max-width: 1160px) {
    /* max-with: 80vw; */
    font-size: 45px;
    line-height: 53px;
  }
  @media ${laptopSize} {
    /* max-with: 80vw; */
    font-size: 40px;
    line-height: 48px;
  }
  @media ${smallMobileSize} {
    font-size: 32px;
    line-height: 42px;
    width: 100%;
    padding: 1rem calc(38 * 2px + 12px); // both arrows
    white-space: normal;
    overflow: visible;
  }
`;

const HiddenText = styled.h1`
  margin: 0;
  opacity: 0;
  font-family: 'ObjektivMk2';
  font-weight: bold;
  font-size: 62px;
  line-height: 72px;
  color: ${theme.WHITE_COLOR};
  text-align: center;
  /* width: clamp(50%, 800px, 90%); */
  /* min-width: auto; */
  /* width: fit-content; */
  /* width: 840px; */
  padding-left: 40px;
  padding-right: 40px;
  white-space: nowrap;
  overflow: hidden;
  @media screen and (max-width: 1160px) {
    font-size: 45px;
    line-height: 53px;
  }
  @media ${laptopSize} {
    font-size: 40px;
    line-height: 48px;
  }
  @media ${smallMobileSize} {
    padding: 0 10px;
    font-size: 32px;
    line-height: 42px;
    white-space: normal;
    overflow: visible;
  }
`;

const widthAnimation = keyframes`
  0% { width: 0%; opacity: 0 }
  35% { width: 100%; opacity: 1 }
  /* 100% { width: 100%; opacity: 1 } */
`;

const TextDiv = styled.div`
  position: relative;
  overflow: hidden;
  animation: ${widthAnimation} ${HERO_ANIMATION_SECONDS}s ease-in-out infinite alternate;
`;

const RelativeDiv = styled.div`
  position: relative;
  height: 220px;
  /* min-width: 800px;
  width: 800px; */
  display: flex;
  align-items: center;
  justify-content: center;
  /* justify-content: space-between; */
  min-width: auto;
  /* width: 100%; */
  @media ${smallMobileSize} {
    width: 100%;
    padding: 0px 20px;
  }
`;

const ButtonsDiv = styled.div`
  display: grid;
  margin: 50px auto 50px auto;
  width: 332px;
  /* min-width: 100%; */
  grid-template-columns: 1fr 1fr;
  grid-gap: 40px;
  /* margin-top: 20px; */
  justify-items: center;
  @media ${mobileSize} {
    width: 100%;
    margin: 0px;
    padding: 50px 60px 50px 60px;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr;
    grid-gap: 26px;
  }
`;

const CenterDiv = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: auto auto;

  @media ${smallMobileSize} {
    justify-content: flex-start;
    margin-top: 150px;
  }
`;

interface BackgroundColor {
  baseColor: string;
  gradientColor: string;
}

const PortfolioContainer = styled.div<BackgroundColor>`
  width: 100%;
  min-height: 800px;
  height: 100vh;
  z-index: 0;
  display: block;
  overflow: auto;
  bottom: 0px;
  /* background-color: ${(props) => props.baseColor};
  background-image: ${(props) =>
    `radial-gradient(circle at 0 100%, rgba(255, 255, 255, 0),${props.gradientColor} 102%)`}; */
  /* background-color: black; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  /* background-color: black; */
  /* background: url(${hexagonBG}); */
  background-color: ${theme.BACKGROUND_COLOR};
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
`;
