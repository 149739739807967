import * as React from 'react';
import styled from 'styled-components';
import { NewAppTitle, NewAppText, NewAppButton } from '../ui';
import { prismicClient, Prismic, RichText } from '../../services/prismic';
import { useEffect, useMemo, useState } from 'react';
import { theme } from '../../utils/theme';
import clientImageBorder from '../../images/picture-border.svg';
import rightChevron from '../../images/RightChevron.svg';
import hexagonBG from '../../images/hexagons2.svg';
import { AiOutlineSetting } from 'react-icons/ai';
import { device, laptopSize, mobileSize } from '../../utils';
import { AnimatePresence, motion, useAnimation } from 'framer-motion';
import adoptionImage from '../../images/ApproachPotentialIcon.svg';
import { AdoptionLogo } from './AdoptionLogo';
import hexagonBGwhite from '../../images/hexagon-white.svg';
import AdoptionImageLottie from './AdoptionImageLottie';
import { Link } from 'gatsby';

const ClientFeedback = () => {
  const [clientFeedback, setClientFeedback] = useState([] as any[]);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [isLoadingFeedback, setIsLoadingFeedback] = useState(true);
  const [clientLogos, setClientLogos] = useState([] as any[]);
  const [isLoadingLogos, setIsLoadingLogos] = useState(true);
  const [paused, setPaused] = useState(false);

  const controls = useAnimation();

  useEffect(() => {
    const fetchData = async () => {
      const response = await prismicClient.query(
        Prismic.Predicates.at('document.type', 'client-feedback'),
        { pageSize: 9999 },
      );
      console.log('prismic response: ', response);
      if (response && response.results.length) {
        console.log('DATA: ', response.results);
        setClientFeedback(
          response.results
            .slice()
            .sort((a: any, b: any) => a.data.position - b.data.position) as any[],
        );
        setIsLoadingFeedback(false);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      const response = await prismicClient.query(
        Prismic.Predicates.at('document.type', 'client-logos'),
        { pageSize: 9999 },
      );
      console.log('prismic response: ', response);
      if (response && response.results.length) {
        console.log('Logos: ', response.results[0].data.logos);
        setClientLogos(response.results[0].data.logos);
        setIsLoadingLogos(false);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const interval = setInterval(async () => {
      if (paused) {
        setPaused(false);
      } else {
        await handleIndexChange(+1);
      }
    }, 7000);
    // return () => clearTimeout(timer);

    return () => clearInterval(interval);
  }, [selectedIndex, paused, clientFeedback]);

  const returnSelectedClientFeedback = useMemo(() => {
    const dataAtIndex = clientFeedback.slice().filter((_, i) => i === selectedIndex);
    if (dataAtIndex.length) {
      return dataAtIndex[0].data;
    }
  }, [selectedIndex, clientFeedback]);

  const handleIndexChange = (number: number) => {
    // This handles the framer motion animations for the arrows
    controls
      .start({
        opacity: 0,
        x: number > 0 ? '-100vw' : '100vw',
        transition: { duration: 0.3, type: 'spring' },
      })
      .then(() =>
        controls.start({
          opacity: 0,
          x: number > 0 ? '100vw' : '-100vw',
          transition: { duration: 0.01 },
        }),
      )
      .then(() =>
        controls.start({
          opacity: 1,
          x: number > 0 ? '100vw' : '-100vw',
          transition: { duration: 0.01 },
        }),
      )
      .then(() =>
        controls.start({
          opacity: 1,
          x: 0,
          transition: { duration: 0.3, type: 'spring' },
        }),
      );

    // This handles the logic
    // const lengthOfArray = clientFeedback.length;
    // if (number === 1) {
    //   if (selectedIndex < lengthOfArray - 1) {
    //     setSelectedIndex((selectedIndex) => selectedIndex + 1);
    //   } else if (number + selectedIndex >= lengthOfArray - 1) {
    //     setSelectedIndex(0);
    //   }
    // } else if (number === -1) {
    //   if (number + selectedIndex >= 0) {
    //     setSelectedIndex((selectedIndex) => selectedIndex - 1);
    //   } else if (number + selectedIndex < 0) {
    //     setSelectedIndex(lengthOfArray - 1);
    //   }
    // }

    if (number === 1) {
      // alert('+1');
      setSelectedIndex((selectedIndex) => {
        return selectedIndex < clientFeedback.length - 1 ? selectedIndex + 1 : 0;
      });
    } else if (number === -1) {
      // alert('-1');
      setSelectedIndex((selectedIndex) => {
        return selectedIndex > 0 ? selectedIndex - 1 : clientFeedback.length - 1;
      });
    }
  };

  return (
    <FullAreaContainer>
      {!isLoadingFeedback && !isLoadingLogos ? (
        <>
          <AreaContainer>
            {isLoadingFeedback ? (
              <div />
            ) : (
              <Container id="clientfeedback">
                <TransparentButton
                  onClick={() => {
                    setPaused(true);
                    handleIndexChange(-1);
                  }}
                >
                  <ClickableImg
                    style={{ transform: 'rotate(180deg)' }}
                    src={rightChevron}
                    alt="Left"
                  />
                </TransparentButton>
                <AnimatePresence>
                  <ClientCommentsContainer
                    key={returnSelectedClientFeedback.uid}
                    animate={controls}
                  >
                    {/* <ClientImageBorder>
                  <ClientImage
                    src={returnSelectedClientFeedback.image.url}
                    alt={RichText.asText(returnSelectedClientFeedback.author)}
                  />
                </ClientImageBorder> */}
                    <ClientText>{RichText.asText(returnSelectedClientFeedback.text)}</ClientText>
                    <ClientName>{RichText.asText(returnSelectedClientFeedback.author)}</ClientName>
                    <ClientTitle>{RichText.asText(returnSelectedClientFeedback.title)}</ClientTitle>
                  </ClientCommentsContainer>
                </AnimatePresence>
                <TransparentButton
                  onClick={() => {
                    setPaused(true);
                    handleIndexChange(1);
                  }}
                >
                  <ClickableImg src={rightChevron} alt="Right" />
                </TransparentButton>
              </Container>
            )}
            <ClientsLogosDiv>
              {clientLogos.map((img) => (
                <ClientLogo src={img.image.url} alt={img.image.name.replace(/\.[^/.]+$/, "")} key={img.id} />
              ))}
            </ClientsLogosDiv>
          </AreaContainer>
          <BlackBackgroundDiv>
            {/* <img src={adoptionImage} alt="adoption" /> */}
            <AdoptionImageLottie />
            <MainText>From Idea to Adoption.</MainText>
            <MainBody>
              From early stages to post launch support, Rapptr is your full-service guide to
              crafting digital products. We realize that app design and development can be complex,
              but our soup-to-nuts approach covers all the facets needed to make your next app a
              success.
            </MainBody>
            <Link to="/approach">
              <ApproachButton>
                <AiOutlineSetting color={theme.LIGHT_BLUE} /> Our Approach
              </ApproachButton>
            </Link>
            {/* <ApproachButton>
              Our Approach <AiOutlineSetting color={theme.LIGHT_BLUE} />
            </ApproachButton> */}
          </BlackBackgroundDiv>
        </>
      ) : (
        <div />
      )}
    </FullAreaContainer>
  );
};

const FullAreaContainer = styled.div`
  position: relative;
`;

const ApproachButton = styled(NewAppButton)`
  font-size: 16px;
  :hover {
    background-color: ${theme.WHITE_COLOR};
    color: ${theme.MAIN_DARK_BLUE};
  }
  @media ${mobileSize} {
    width: 100%;
  }
`;

const TransparentButton = styled(NewAppButton)`
  border: transparent;
  background: transparent;
  height: max-content;
  width: max-content;
  @media ${mobileSize} {
    padding: 0px;
    margin: 0px;
    max-width: 50px;
    width: 50px;
    min-width: 50px;
  }
`;

const ClientLogo = styled.img`
  @media ${laptopSize} {
    margin: 20px 0px;
  }
`;

const MainBody = styled(NewAppText as any)`
  font-style: normal;
  font-size: 18px;
  line-height: 22px;
  /* or 122% */
  max-width: 815px;
  text-align: center;
`;

const MainText = styled(NewAppTitle as any)`
  font-style: normal;
  font-weight: bold;
  font-size: 42px;
  line-height: 50px;
  text-align: center;
  padding-top: 20px;
`;

const BlackBackgroundDiv = styled.div`
  /* background: url(${hexagonBG}); */
  background-color: ${theme.BACKGROUND_COLOR};
  min-height: calc(100vw * 850 / 1920);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 200px 0px 150px 0px;
  @media ${laptopSize} {
    min-height: fit-content;
    padding: 50px;
  }
`;

const AreaContainer = styled.div`
  position: relative;
  background: ${theme.WHITE_COLOR};
  /* background: url(${hexagonBGwhite}); */
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
`;

const ClientsLogosDiv = styled.div`
  position: absolute;
  width: 1040px;
  height: 242px;
  bottom: -93px;
  left: calc(50% - 520px);
  background: ${theme.WHITE_COLOR};
  /* box-shadow: 1.8471px 3.6942px 1.8471px rgba(0, 0, 0, 0.25); */
  box-shadow: 0px 0px 10px 0px #0000001a;
  border-radius: 2px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  justify-items: center;
  align-items: center;
  padding: 30px;

  @media ${laptopSize} {
    position: relative;
    grid-template-columns: 1fr 1fr;
    width: 100%;
    background: ${theme.WHITE_COLOR};
    width: 100%;
    height: fit-content;
    bottom: 0px;
    left: 0px;
    padding-bottom: 40px;
  }

  @media ${mobileSize} {
    position: relative;
    grid-template-columns: 1fr;
    width: 100%;
    background: ${theme.WHITE_COLOR};
    width: 100%;
    height: fit-content;
    bottom: 0px;
    left: 0px;
    padding: 40px;
    /* padding-bottom: 40px; */
  }
`;

const Container = styled.div`
  display: flex;
  /* width: 100vw; */
  /* margin: auto auto; */
  align-items: center;
  justify-content: center;
  min-height: 750px;
  padding-bottom: 149px;
  @media ${laptopSize} {
    /* width: 100%; */
    min-height: 500px;
    padding-bottom: 20px;
    /* display: grid;
    grid-template-columns: 40px 1fr 40px; */
  }
`;

const ClientName = styled(NewAppText as any)`
  font-weight: bold;
  font-size: 21px;
  line-height: 25px;
  color: ${theme.MAIN_DARK_BLUE};
  margin: 0px;
  margin-bottom: 15px;
  text-align: center;

  @media ${mobileSize} {
    font-size: 12px;
  }
`;
const ClientTitle = styled(NewAppText as any)`
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  color: ${theme.MAIN_DARK_BLUE};
  margin: 0px;
  text-align: center;

  @media ${mobileSize} {
    font-size: 12px;
  }
`;

const ClickableImg = styled.img`
  /* cursor: pointer; */
  /* width: 30px; */
  @media ${mobileSize} {
    padding: 0px;
    margin: 0px;
    width: 30px;
  }
`;

const ClientCommentsContainer = styled(motion.div)`
  width: 100%;
  max-width: 900px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 40px 0px;
  overflow: visible;
  @media ${laptopSize} {
    margin: 10px;
  }
`;

const ClientText = styled(NewAppTitle as any)`
  font-style: italic;
  font-weight: bold;
  font-size: 32px;
  line-height: 42px;
  color: ${theme.MAIN_DARK_BLUE};
  margin: 30px 0px;
  max-width: 870px;
  text-align: center;

  @media ${mobileSize} {
    font-size: 14px;
    line-height: 28px;
  }
`;

export default ClientFeedback;
