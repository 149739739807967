import React, { useRef, useEffect } from 'react';
import { Player } from '@lottiefiles/react-lottie-player';
import { useInView } from 'react-intersection-observer';
const rapptrAdoptionJson = require('../../static/RapptrApproachLottie.json');

const AdoptionImageLottie = () => {
  const { ref, inView } = useInView();
  const player = useRef(null);

  const pauseForABit = () => {
    player.current.pause();
  };

  useEffect(() => {
    if (inView) {
      player.current.play();
    }
  }, [inView]);

  return (
    <div ref={ref} style={{ marginBottom: '60px' }}>
      <Player
        onEvent={(event) => {
          if (event === 'load') pauseForABit();
        }}
        autoplay
        loop
        ref={player}
        src={rapptrAdoptionJson}
      />
    </div>
  );
};

export default AdoptionImageLottie;
