import React from 'react';
import { Player, Controls } from '@lottiefiles/react-lottie-player';
import styled from 'styled-components';

const rapptrLogoJson = require('../../static/RapptrLogoLotte.json');

interface IEndFunction {
  restOfAnimation: () => void;
}

class LottieLogo extends React.Component<IEndFunction> {
  constructor(props) {
    super(props);
    this.player = React.createRef();
  }

  pauseForABit() {
    this.player.current.pause(); // make use of the player and call methods

    setTimeout(() => {
      this.player.current.play();
    }, 1000);

    setTimeout(() => {
      this.props.restOfAnimation();
    }, 3500);
  }

  render() {
    return (
      <div style={{ height: '170px', margin: '0px' }}>
        <Player
          onEvent={(event) => {
            if (event === 'load') this.pauseForABit();
          }}
          ref={this.player}
          src={rapptrLogoJson}
          style={{ height: '188px', width: '188px' }}
        />
      </div>
    );
  }
}

export default LottieLogo;
