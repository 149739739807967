import * as React from 'react';
import styled from 'styled-components';
import { IndexLayout } from '../layouts';
import ClientFeedback from '../components/segments/ClientFeedback';
import LetsTalk from '../components/segments/LetsTalk';
import DarkFooter from '../components/segments/DarkFooter';
import { NavBar } from '../components/segments/NavBar';
import { Helmet } from 'react-helmet';
import WorkShelves from '../components/segments/WorkShelves';
import HeroSplash from '../components/segments/HeroSplash';

export default () => {
  // interface IndexProp {
  //   index: number;
  // }

  // const ReturnStickyDiv: React.FC<IndexProp> = ({ index }) => {
  //   const { ref, inView, entry } = useInView({
  //     threshold: 0.5,
  //   });

  //   return (
  //     <MinHeightDiv2
  //       style={{
  //         position: entry ? 'fixed' : 'relative',
  //         zIndex: index * 3,
  //       }}
  //       ref={ref}
  //       inView={inView}
  //     >
  //       <div style={{ margin: 'auto auto', backgroundClip: 'silver' }}>x</div>
  //     </MinHeightDiv2>
  //   );
  // };

  // useEffect(() => {
  //   if (inView) {
  //     controls.start('visible');
  //   }
  //   if (!inView) {
  //     controls.start('hidden');
  //   }
  // }, [controls, inView]);

  return (
    <NewIndexLayout>
      <Helmet>
        <title>Rapptr Labs | App Design & Development Company | NYC</title>
        <meta
          name="description"
          content="We're an app design & development company near NYC working with startups & leading brands to develop cutting-edge apps that solve problems and drive results."
        />
        <meta name="theme-color" content="#0B131C" media="(prefers-color-scheme: dark)" />
        {/* <script async src="https://www.googletagmanager.com/gtag/js?id=UA-128203228-5"></script>
        <script>
          {`  window.dataLayer = window.dataLayer || [];
  function gtag(){dataLayer.push(arguments);}
  gtag('js', new Date());

  gtag('config', 'UA-128203228-5');`}
        </script>
        <script src="https://1d022a36a1944a07bd0c2be4c51b1580.js.ubembed.com" async></script> */}
        <link rel="stylesheet" href="https://use.typekit.net/yxm3bov.css" />
      </Helmet>
      <NavBar />
      <HeroSplash />
      {typeof window !== 'undefined' && (
        <>
          <WorkShelves />
          <ClientFeedback />
          <LetsTalk />
          <DarkFooter />
        </>
      )}
    </NewIndexLayout>
  );
};

const NewIndexLayout = styled(IndexLayout)``;
